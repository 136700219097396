// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  local: false,
  gaTrackingCode: 'UA-85548337-8',
  adminEmail: 'admin@spaced.be',
  sentryEnvironment: 'staging',
  apiUrl: 'https://testing.straatvinken.spaced.be/api/',
  fakeCountMoment: true,
  fakeTestDuration: 6, // in minuten
  fakeTestCountdownDuration: 1, // in minuten
  debug: true,
  privacyPolicyURL: 'http://straatvinken.be/gdpr',
  google: {
    geoCode: {
      apiKey: 'AIzaSyDaCmV72NWUZk5C7IJRrpDCcolgIULUbko'
    }
  },
  firebase: {
    apiKey: 'AIzaSyChP-H65LeD-X4eLpCKC1R-8L3xnErRY8c',
    authDomain: 'straatvinken-test.firebaseapp.com',
    projectId: 'straatvinken-test',
    storageBucket: 'straatvinken-test.appspot.com',
    messagingSenderId: '793576805745',
    appId: '1:793576805745:web:ed149ac40d5bd0640d52d0'
  },
  mapbox: {
    accessToken: 'pk.eyJ1Ijoic2ltb25zcGFjZWQiLCJhIjoiY2ttM2hmcDVjMjV5MTJxbHlvOHk4dW9xaCJ9.rnDdShMCw_HAfA918HfUHA'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
